import React from 'react'
import './login-form.css'
import { FaUser, FaLock } from 'react-icons/fa'

const Login = () => {
  return (
    <div id="login-form">
      <div className="wrapper">
        <form>
          <h1>Login</h1>
          <div className="imput-box">
            <input type="text" id="cpf" name="cpf" placeholder="CPF" required />
            <FaUser className="icon" />
          </div>

          <div className="imput-box">
            <input type="password" id="password" name="password" placeholder="Senha" required />
            <FaLock className="icon" />
          </div>

          <div className="remember-forgot">
            <label>
              <input type="checkbox" name="remember" />
              Lembre de mim
            </label>
            <a href="https://www.google.com">Esqueceu sua senha?</a>
          </div>

          <button type="submit">Entrar</button>

          <div className="signup-link">
            <p>
              Não tem uma conta? <a href="https://www.google.com">Clique aqui</a>
            </p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
